import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { lastWorkstepAction, nextWorkstepAction, previousWorkstepAction } from '@/worksteps/worksteps.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqStateSynchronizer, sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { fetchAnnotations } from '@/annotation/annotation.actions';
import { WorkstepOutput } from '@/worksteps/worksteps.utilities';

export type WorkstepButtonType = 'previous' | 'next' | 'last';

interface WorkstepIconsProps {
  isViewMode: boolean;
  isEditMode: boolean;
}

export const WorkstepIcons: React.FunctionComponent<WorkstepIconsProps> = ({ isViewMode, isEditMode }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { previous, next, last, current, isError } = useFlux(sqWorkstepsStore);
  const previousAvailable = !_.isEmpty(previous);
  const nextAvailable = !_.isEmpty(next);
  const lastAvailable = !_.isEmpty(last) && last !== current.id;

  const { t } = useTranslation();
  /**
   * Navigates to the specified workstep if it's available.
   *
   * @param which - The identifier (previous, next, last) of the workstep to navigate to
   * @param isAvailable - A flag identifying if the desired workstep is available
   * @returns A promise that resolves when the workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  const goToWorkstep = async (which: WorkstepButtonType, isAvailable: boolean) => {
    if (isAvailable) {
      let getWorkstep: (workbookId: string, worksheetId: string) => Promise<WorkstepOutput>;
      switch (which) {
        case 'previous':
          getWorkstep = previousWorkstepAction;
          break;
        case 'next':
          getWorkstep = nextWorkstepAction;
          break;
        case 'last':
          getWorkstep = lastWorkstepAction;
          break;
      }

      const result = await sqStateSynchronizer.getWorkstepAndRehydrate(() =>
        getWorkstep(sqWorkbenchStore.stateParams.workbookId, sqWorkbenchStore.stateParams.worksheetId),
      );
      return sqStateSynchronizer.withPushDisabled(() => fetchAnnotations(result));
    }
  };

  return (
    <div
      className={isViewMode ? 'flexSelfStretch' : 'flexColumnContainer flexJustifyEnd ml10 mr1 flexNoGrowNoShrink'}
      data-testid="workstepIcons">
      {isEditMode && !sqWorkbookStore.isReportBinder && (
        <div className="unselectable">
          {isError && (
            <Icon
              icon="fa-exclamation-triangle"
              extraClassNames="workstepButton fa-xlg pr10"
              type="danger"
              tooltip={t('WORKSTEPS.ERROR')}
              tooltipPlacement="bottom"
            />
          )}
          <Icon
            icon="fa-reply"
            extraClassNames={classNames(
              {
                'workstepButton': previousAvailable,
                'sq-icon-gray': !previousAvailable,
              },
              'fa-xlg pr12',
            )}
            type={previousAvailable ? 'theme-light' : 'gray'}
            onClick={
              previousAvailable
                ? () => {
                    goToWorkstep('previous', previousAvailable);
                  }
                : _.noop
            }
            testId="previousWorkstep"
            tooltip={t('WORKSTEPS.PREVIOUS')}
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-share"
            extraClassNames={classNames(
              {
                'workstepButton': nextAvailable,
                'sq-icon-gray': !nextAvailable,
              },
              'fa-xlg pr10',
            )}
            type={nextAvailable ? 'theme-light' : 'gray'}
            onClick={
              nextAvailable
                ? () => {
                    goToWorkstep('next', nextAvailable);
                  }
                : _.noop
            }
            testId="nextWorkstep"
            tooltip={t('WORKSTEPS.NEXT')}
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-reply-all"
            extraClassNames={classNames(
              {
                'workstepButton': lastAvailable,
                'sq-icon-gray': !lastAvailable,
              },
              'fa-xlg pr3 pl9 fa-flip-horizontal',
            )}
            type={lastAvailable ? 'theme-light' : 'gray'}
            onClick={
              lastAvailable
                ? () => {
                    goToWorkstep('last', lastAvailable);
                  }
                : _.noop
            }
            testId="lastWorkstep"
            tooltip={t('WORKSTEPS.LAST')}
            tooltipPlacement="bottom"
          />
        </div>
      )}
    </div>
  );
};
