import { PersistenceLevel, Store } from '@/core/flux.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ColumnDefinitionInputV1, TableDefinitionInputV1, TableDefinitionOutputV1 } from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import {
  columnDefinitionOutputToColumnDefinitionInput,
  processMaterializedTable,
  tableDefinitionOutputToTableDefinitionInput,
} from '@/tableDefinitionEditor/tableDefinition.utilities';
import {
  DEFAULT_TABLE_DEFINITION_NAME,
  MaterializedTableItemColumnPropertyPair,
  MaterializedTableOutput,
  ProcessedMaterializedTable,
  ScalingTableColumnDefinition,
} from '@/tableDefinitionEditor/tableDefinition.types';
import { columnRuleOutputToColumnRuleInput } from '@/tableDefinitionEditor/columnRules/columnRule.utilities';
import { t } from 'i18next';

export const ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.ItemIdColumn,
  columnType: ColumnTypeEnum.UUID,
  columnRules: [{ eventProperty: { propertyName: 'id' } }],
};

export const DATUM_ID_COLUMN: ColumnDefinitionInputV1 = {
  columnName: SeeqNames.MaterializedTables.DatumIdColumn,
  columnType: ColumnTypeEnum.TEXT,
  columnRules: [{ eventProperty: { propertyName: '' } }],
};

export const DEFAULT_COLUMN_DEFINITION_INPUTS = [ID_COLUMN, DATUM_ID_COLUMN];

export class TableDefinitionStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqTableDefinitionStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      subscriberId: '',
      name: DEFAULT_TABLE_DEFINITION_NAME,
      description: '',
      scopedTo: undefined,
      columns: [],
      hasUnsupportedSubscription: undefined,
      shouldSearchForItems: false,
      tableDefinitionInput: this.monkey(
        ['subscriberId'],
        ['name'],
        ['description'],
        ['scopedTo'],
        ['columns'],
        function (
          subscriberId: string,
          name: string,
          description: string,
          scopedTo: string | undefined,
          columnDefinitions: ScalingTableColumnDefinition[],
        ): TableDefinitionInputV1 {
          const columnDefinitionInputs =
            columnDefinitions.length === 0
              ? DEFAULT_COLUMN_DEFINITION_INPUTS
              : columnDefinitions.map((column) =>
                  columnDefinitionOutputToColumnDefinitionInput(column, columnDefinitions, { scopedTo }),
                );

          return {
            subscriptionId: subscriberId,
            name,
            description,
            scopedTo,
            columnDefinitions: columnDefinitionInputs,
          };
        },
      ),
      tableDefinitionOutput: undefined,
      materializedTable: undefined,
      doTableReload: false,
      itemColumnsThatCouldBeUsedToAutoCreateTextColumns: this.monkey(
        ['columns'],
        (columns: ScalingTableColumnDefinition[]) => {
          return (
            columns
              .map((column, index) => ({ ...column, columnIndex: index + 1 }))
              .filter((column) => column.columnType === ColumnTypeEnum.UUID && column.propertyToDisplay)
              // filter out an item column if there is a text column whose first rule is item property and has the same property
              // as the item column is currently displaying
              .filter((itemColumnWithProperty) => {
                const textColumnWithItemPropertyRule = columns.find((col) => {
                  const itemPropertyRule =
                    col.columnType === ColumnTypeEnum.TEXT &&
                    col.rules.length > 0 &&
                    col.rules[0].rule === SeeqNames.MaterializedTables.Rules.ItemProperty
                      ? columnRuleOutputToColumnRuleInput(
                          col.rules[0],
                          columns.filter((columnDef) => columnDef.columnName !== col.columnName),
                          { scopedTo: this.scopedTo },
                        )
                      : undefined;
                  const isPropertyMatch =
                    itemPropertyRule?.itemProperty?.propertyName === itemColumnWithProperty.propertyToDisplay;
                  const inputColumnOfRuleIsItemColumn =
                    itemPropertyRule?.itemProperty?.columnIndex === itemColumnWithProperty.columnIndex;
                  return isPropertyMatch && inputColumnOfRuleIsItemColumn ? col : undefined;
                });
                return !textColumnWithItemPropertyRule;
              })
              .map((column) => ({
                columnName: column.columnName,
                propertyToDisplay: column.propertyToDisplay,
                displayName: column.displayName,
              })) as Required<Pick<ScalingTableColumnDefinition, 'columnName' | 'displayName' | 'propertyToDisplay'>>[]
          );
        },
      ),
      pendingTableIds: {},
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get shouldSearchForItems(): boolean {
    return this.state.get('shouldSearchForItems');
  }

  get pendingTableIds(): Record<string, boolean> {
    return this.state.get('pendingTableIds');
  }

  get subscriberId(): string {
    return this.state.get('subscriberId');
  }

  get name(): string {
    return this.state.get('name');
  }

  get description(): string {
    return this.state.get('description');
  }

  get scopedTo(): string | undefined {
    return this.state.get('scopedTo');
  }

  get columns(): ScalingTableColumnDefinition[] {
    return this.state.get('columns');
  }

  get tableDefinitionInput(): TableDefinitionInputV1 {
    return this.state.get('tableDefinitionInput');
  }

  get tableDefinitionOutput(): TableDefinitionOutputV1 {
    return this.state.get('tableDefinitionOutput');
  }

  get materializedTable(): ProcessedMaterializedTable | undefined {
    return this.state.get('materializedTable');
  }

  get doTableReload(): boolean {
    return this.state.get('doTableReload');
  }

  get itemColumnsThatCouldBeUsedToAutoCreateTextColumns(): Required<
    Pick<ScalingTableColumnDefinition, 'columnName' | 'displayName' | 'propertyToDisplay'>
  >[] {
    return this.state.get('itemColumnsThatCouldBeUsedToAutoCreateTextColumns');
  }

  get hasUnsupportedSubscription(): boolean | undefined {
    return this.state.get('hasUnsupportedSubscription');
  }

  createdItemFinderId: string | undefined = undefined;

  private getColumnDisplayName = (columnName: string): string => {
    if (columnName === ID_COLUMN.columnName) {
      return t('SCALING.DEFAULT_ITEM_ID_COLUMN_NAME');
    }

    if (!this.subscriberId && columnName === DATUM_ID_COLUMN.columnName) {
      return t('SCALING.DEFAULT_DATUM_ID_COLUMN_NAME');
    }

    return columnName;
  };

  protected readonly handlers = {
    TABLE_DEFINITION_SET_SUBSCRIBER_ID: ({ subscriberId }: { subscriberId: string | undefined }) => {
      this.state.set('subscriberId', subscriberId);
    },

    TABLE_DEFINITION_SET_SHOULD_SEARCH_FOR_ITEMS: ({ shouldSearchForItems }: { shouldSearchForItems: boolean }) => {
      this.state.set('shouldSearchForItems', shouldSearchForItems);
    },

    TABLE_DEFINITION_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },

    TABLE_DEFINITION_SET_DESCRIPTION: ({ description }: { description: string }) => {
      this.state.set('description', description);
    },

    TABLE_DEFINITION_SET_DO_TABLE_RELOAD: ({ doTableReload }: { doTableReload: boolean }) => {
      this.state.set('doTableReload', doTableReload);
    },

    TABLE_DEFINITION_SET_TABLE_DEFINITION: ({ tableDefinition }: { tableDefinition: TableDefinitionOutputV1 }) => {
      this.state.set('tableDefinitionOutput', tableDefinition);

      this.state.set('id', tableDefinition.id);
      this.state.set('name', tableDefinition.name);

      const hasUnsupportedSubscription = tableDefinition.subscription?.type === SeeqNames.Types.ConditionMonitor;
      this.state.set('hasUnsupportedSubscription', hasUnsupportedSubscription);
      if (hasUnsupportedSubscription) {
        return;
      }

      const tableDefinitionInput = tableDefinitionOutputToTableDefinitionInput(tableDefinition);
      this.state.set('subscriberId', tableDefinitionInput.subscriptionId);
      this.state.set('description', tableDefinitionInput.description);
      this.state.set('scopedTo', tableDefinitionInput.scopedTo);
      const currentColumns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const newColumnDefinitions = tableDefinition.columnDefinitions;
      const updatedColumns: ScalingTableColumnDefinition[] = newColumnDefinitions.map((newColumn) => {
        const existingColumn = currentColumns.find((column) => column.columnName === newColumn.columnName);
        if (existingColumn) {
          return {
            ...existingColumn,
            ...newColumn,
          };
        } else {
          return {
            ...newColumn,
            propertyToDisplay: newColumn.columnType === ColumnTypeEnum.UUID ? SeeqNames.Properties.Name : undefined,
            displayName: this.getColumnDisplayName(newColumn.columnName),
          };
        }
      });
      this.state.set('columns', updatedColumns);
    },

    TABLE_DEFINITION_SET_MATERIALIZED_TABLE: ({
      materializedTable,
    }: {
      materializedTable: MaterializedTableOutput;
    }) => {
      const columns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const processedMaterializedTable = processMaterializedTable(materializedTable, columns);
      this.state.set('materializedTable', processedMaterializedTable);
    },

    TABLE_DEFINITION_SET_PROPERTY_NAME_FOR_UUID_COLUMN: ({
      uuidColumnToPropertyNamePair,
    }: {
      uuidColumnToPropertyNamePair: MaterializedTableItemColumnPropertyPair;
    }) => {
      let doTableReload = true;
      const columns: ScalingTableColumnDefinition[] = this.state.get('columns');
      const newProperty = uuidColumnToPropertyNamePair.propertyName;
      const isIdProperty = newProperty === SeeqNames.Properties.Id;

      const updatedColumns = columns.map((column) => {
        if (column.columnName !== uuidColumnToPropertyNamePair.uuidColumn) {
          return column;
        }
        const additionalProperties = column.additionalProperties ? [...column.additionalProperties] : [];
        const newPropertyIsInAdditionalProperties = additionalProperties.includes(newProperty);
        if (newPropertyIsInAdditionalProperties || isIdProperty) {
          if (newPropertyIsInAdditionalProperties) {
            additionalProperties.splice(additionalProperties.indexOf(newProperty), 1);
          }
          doTableReload = false;
        }
        if (column.propertyToDisplay) {
          additionalProperties.push(column.propertyToDisplay);
        }
        return {
          ...column,
          additionalProperties,
          propertyToDisplay: isIdProperty ? undefined : newProperty,
        };
      });
      this.state.set('columns', updatedColumns);
      this.handlers.TABLE_DEFINITION_SET_DO_TABLE_RELOAD({ doTableReload });
    },

    TABLE_DEFINITION_RESET: () => {
      this.state.set('id', '');
      this.state.set('subscriberId', '');
      this.state.set('name', DEFAULT_TABLE_DEFINITION_NAME);
      this.state.set('description', '');
      this.state.set('scopedTo', undefined);
      this.state.set('materializedTable', undefined);
      this.state.set('doTableReload', false);
      this.state.set('columns', []);
    },

    TABLE_DEFINITION_ADD_PENDING_TABLE_ID: ({ pendingTableId }: { pendingTableId: string }) => {
      this.state.set(['pendingTableIds', pendingTableId], true);
    },

    TABLE_DEFINITION_REMOVE_PENDING_TABLE_ID: ({ pendingTableId }: { pendingTableId: string }) => {
      this.state.unset(['pendingTableIds', pendingTableId]);
    },
  };
}
