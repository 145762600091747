import { STRING_UOM } from '@/main/app.constants';

/**
 * Represents an item that has units. valueUnitOfMeasure is required, but other fields are optional.
 */
export interface ItemWithUnits {
  displayUnit?: string;
  sourceValueUnitOfMeasure?: string;
  valueUnitOfMeasure: string;
}

/**
 * Represents what unit should be displayed for an item. Has additional metadata to give extra context when displaying.
 */
export interface UnitOfMeasure {
  value: string;
  fromSourceUom: boolean;
  isDisplay: boolean;
}

export function resolveUnitOfMeasure(item: ItemWithUnits): UnitOfMeasure {
  // use displayUnit if present
  if (item.displayUnit) {
    return { value: item.displayUnit, fromSourceUom: false, isDisplay: true };
  }
  // valueUom is actually used by Seeq, may be unitless (empty string).
  // sourceValueUom is how the string came into Seeq from the datasource.
  // If valueUom is empty and sourceValueUom is not, then use the sourceUom.
  const valueUom = item.valueUnitOfMeasure;
  const sourceValueUom = item.sourceValueUnitOfMeasure;
  const fromSourceUom: boolean = sourceValueUom !== undefined && valueUom === '' && valueUom !== sourceValueUom;
  let value: string;
  if (fromSourceUom) {
    value = sourceValueUom!;
  } else {
    // In CRAB-8217, users found the 'string' unit of measure as confusing, so we hide it.
    if (valueUom === STRING_UOM) {
      value = '';
    } else {
      value = valueUom;
    }
  }
  return { value, fromSourceUom, isDisplay: false };
}
