import React from 'react';
import { createRoot } from 'react-dom/client';
import { init18n } from '@/utilities/i18n.utilities';
import './styles';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
// uncomment the wdyr import to enable why-did-you-render
// import './wdyr'; // Note this must be the first import for it to work
import { Application } from '@/main/Application.page';

// https://github.com/seeq12/crab/pull/5962/files#r1446538620
momentDurationFormatSetup(moment as any);

init18n();
const root = createRoot(document.getElementById('sqApp') || document.createElement('div'));
root.render(<Application />);
