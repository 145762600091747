import React from 'react';
import classNames from 'classnames';
import { Modal } from '@seeqdev/qomponents';
import { SelectItemSearchWidget, SelectItemSearchWidgetProps } from '@/core/SelectItemSearchWidet.molecule';

interface SelectAssetModalIF extends SelectItemSearchWidgetProps {
  onClose: () => void;
  showHeaderCloseButton?: boolean;
  testId?: string;
  isMultiple?: boolean;
  header: {
    title: string;
    isEditable?: boolean;
    isTitleRequired?: boolean;
    titleIcon?: string;
    isInvalid?: boolean;
    placeholder?: string;
    onChange?: (e: any) => void;
  };
  /** HTML that should appear above search widget, but below title **/
  body: JSX.Element | HTMLElement | null;
  /** HTML that should appear below the search widget **/
  footer: JSX.Element | HTMLElement | null;
  selectedAssetIds?: string | string[];
  selectAllCallback?: (itemIds: string[]) => void;
  areAllSelected?: (itemIds: string[]) => boolean;
}

export const SelectAssetModal: React.FunctionComponent<SelectAssetModalIF> = ({
  onClose,
  header,
  body,
  footer,
  showHeaderCloseButton = true,
  onSelect,
  iconPartialTooltipKey,
  selectedAssetIds,
  validateItem,
  scopeIds,
  excludeGloballyScoped = false,
  testId = 'selectAssetModal',
  modalId = testId,
  searchTypes,
  isMultiple,
  selectAllCallback = undefined,
  areAllSelected = () => false,
  showPinnedAssets = false,
}) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      size="sm"
      dialogClassName="scrollableSearchModal"
      testId={testId}
      title={header.title}
      isTitleEditable={header.isEditable}
      titleRequired={header.isTitleRequired}
      inputExtraClassNames={classNames('width-maximum', {
        'error-border': header.isInvalid,
      })}
      titlePlaceholder={header.placeholder}
      onTitleChanged={header.onChange}
      titleIcon={header.titleIcon}
      hideCloseIcon={!showHeaderCloseButton}
      modalFooter={footer as React.ReactElement}>
      <div>
        <>
          {body}
          <SelectItemSearchWidget
            onSelect={onSelect}
            iconPartialTooltipKey={iconPartialTooltipKey}
            itemId={selectedAssetIds}
            validateItem={validateItem}
            scopeIds={scopeIds}
            excludeGloballyScoped={excludeGloballyScoped}
            modalId={modalId}
            searchTypes={searchTypes}
            isMultiple={isMultiple}
            selectAllCallback={selectAllCallback}
            areAllSelected={areAllSelected}
            showPinnedAssets={showPinnedAssets}
          />
        </>
      </div>
    </Modal>
  );
};
