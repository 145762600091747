import _ from 'lodash';
import { ColumnRuleOutputV1 } from 'sdk/model/ColumnRuleOutputV1';
import { ColumnDefinitionOutputV1, ColumnRuleInputV1 } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AllColumnEnumOptions, ColumnRule } from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { ColumnRulesWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.constants';
import { getColumnRuleWithMetaData } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.utilities';
import { ColumnRuleWithMetadata } from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { TableDefinitionAccessSettings } from '@/tableDefinitionEditor/tableDefinition.types';

export const getAllowedColumnTypes = (columnRule: ColumnRule): ColumnTypeEnum[] => {
  return getColumnRuleWithMetaData(columnRule)?.allowedOnColumnTypes ?? [];
};

export const getRulesAllowedOnColumnType = (columnType: ColumnTypeEnum): ColumnRuleWithMetadata[] => {
  return ColumnRulesWithMetaData.filter((columnRule) => columnRule.allowedOnColumnTypes.includes(columnType));
};

export const getAllowedColumnTypesGivenCurrentColumnAndSetOfRules = (
  ruleInputs: ColumnRuleInputV1[],
  currentColumnTypeIfConfigured?: ColumnTypeEnum,
): ColumnTypeEnum[] => {
  return ruleInputs.reduce((commonTypes, ruleInput) => {
    const rule: ColumnRule = getRuleTypeFromRuleInput(ruleInput);
    let currentList: ColumnTypeEnum[];
    if (
      rule === SeeqNames.MaterializedTables.Rules.Constant.BaseConstant &&
      currentColumnTypeIfConfigured !== undefined
    ) {
      // A constant rule that has been configured on a given column is by necessity of the same type as the
      // column, so we don't want to allow changing the column type if you have a configured constant rule
      currentList = [currentColumnTypeIfConfigured];
    } else {
      currentList = getAllowedColumnTypes(rule);
    }
    return _.compact(commonTypes.filter((type) => currentList.includes(type)));
  }, AllColumnEnumOptions);
};

export const getRuleTypeFromRuleInput = (ruleInput: ColumnRuleInputV1): ColumnRule => {
  // Each rule input should have only one field filled out, so we can just get the first one as the rule type
  return Object.keys(ruleInput)[0] as ColumnRule;
};

export const columnRuleOutputToColumnRuleInput = (
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
  accessSettings: TableDefinitionAccessSettings,
): ColumnRuleInputV1 => {
  // The constant rule is unique in that there is only 1 api input type but multiple api output types
  const isConstantRule = /constant/i.test(ruleOutput.rule);
  const ruleType = isConstantRule ? SeeqNames.MaterializedTables.Rules.Constant.BaseConstant : ruleOutput.rule;
  if (!isValidColumnRule(ruleType)) {
    throw new Error(`Invalid rule type: ${ruleType}`);
  }

  const columnRuleWithMetadata = getColumnRuleWithMetaData(ruleType);
  if (!columnRuleWithMetadata) {
    throw new Error(`Could not find metadata for rule: ${ruleType}`);
  }

  return {
    [ruleType]: columnRuleWithMetadata.columnRuleOutputToColumnRuleInput(ruleOutput, otherColumns, accessSettings),
  };
};

const isValidColumnRule = (rule: string): rule is ColumnRule => {
  return ColumnRulesWithMetaData.some((columnRule) => columnRule.rule === rule);
};
