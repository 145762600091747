import React, { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { WorkbenchWrapperPage } from '@/workbench/WorkbenchWrapper.page';
import { Login } from '@/main/auth/Login.page';
import { Unauthorized } from '@/main/Unauthorized.page';
import { Builder } from '@/builder/Builder.molecule';
import { LoadError } from '@/main/LoadError.page';
import { initializeHeadlessCaptureMode } from '@/utilities/screenshot.utilities';
import { ProtectedComponent } from '@/main/ProtectedComponents';
import { canReadAuditTrail } from '@/services/authorization.service';
import { Main } from '@/main/Main.page';
import { Root } from '@/core/Root';
import { logError } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { RouteError } from '@/main/RouteError.atom';
import { ToastContainer } from 'react-toastify';
import { Unsubscribe } from '@/notifications/Unsubscribe.page';
import _ from 'lodash';
import { initializeReduxDevtools } from '@/utilities/reduxDevtools.utilities';
import { QTip } from '@seeqdev/qomponents';
import { isProtractor } from '@/core/utilities';

// React.lazy only works via default exports so we import shim files which export a default component
// matching the given route
const Administration = React.lazy(() => import('@/defaultExportShims/Administration'));
const WorksheetWrapper = React.lazy(() => import('@/defaultExportShims/WorksheetWrapper'));
const HomeScreen = React.lazy(() => import('@/defaultExportShims/HomeScreen'));
const HomeScreenAddOnHost = React.lazy(() => import('@/defaultExportShims/HomeScreenAddOnHost'));
const ViewWrapper = React.lazy(() => import('@/defaultExportShims/ViewWrapper'));
const License = React.lazy(() => import('@/defaultExportShims/License'));
const LogTracker = React.lazy(() => import('@/defaultExportShims/LogTracker'));
const DataLabWrapper = React.lazy(() => import('@/defaultExportShims/DataLabWrapper'));
const AuditTrail = React.lazy(() => import('@/defaultExportShims/AuditTrail'));
const ReportTemplateThumbnail = React.lazy(() => import('@/defaultExportShims/ReportTemplateThumbnail'));
const NotificationsHistory = React.lazy(() => import('@/defaultExportShims/NotificationsHistory'));
const NotificationsManagement = React.lazy(() => import('@/defaultExportShims/NotificationsManagement'));
const AiAssistant = React.lazy(() => import('@/defaultExportShims/AiAssistant'));

initializeHeadlessCaptureMode();
prepareForTests();
initializeReduxDevtools();

function prepareForTests() {
  if (isProtractor()) {
    const originalDebounce = _.debounce;
    // @ts-ignore For protractor tests we want to disable debounce completely
    _.debounce = (fn: any, wait: any, { isAllowedDuringTest, ...options } = { isAllowedDuringTest: false }) => {
      return isAllowedDuringTest
        ? originalDebounce(fn, wait, options)
        : _.assign(fn, { cancel: _.noop, flush: _.noop });
    };
  }
}

const worksheetRoutePaths = [
  'workbook/:workbookId/worksheet/:worksheetId',
  'workbook/:workbookId/worksheet',
  'workbook/:workbookId',
  ':currentFolderId/workbook/:workbookId/worksheet/:worksheetId',
  ':currentFolderId/workbook/:workbookId/worksheet',
  ':currentFolderId/workbook/:workbookId',
  'view/worksheet/:workbookId/:worksheetId',
  'view/worksheet/:workbookId',
  ':currentFolderId/view/worksheet/:workbookId/:worksheetId',
  ':currentFolderId/view/worksheet/:workbookId',
  'present/worksheet/:workbookId/:worksheetId',
  'present/worksheet/:workbookId',
];

/**
 * Application is the de-facto Root component of the application where all the routes are configured
 */
export const Application: React.FunctionComponent = () => {
  useEffect(() => {
    window.onerror = function (errorMsg, url, lineNumber, colNumber, errObject) {
      if (errObject && errObject.stack) {
        logError(errObject);
      } else {
        logError(new Error(`${errorMsg} in ${url}, line: ${lineNumber}, column: ${colNumber}`));
      }
    };
    const rejectionHandle = (event: PromiseRejectionEvent) => {
      logError(formatMessage`Unhandled exception: ${event.reason}`);
      event.preventDefault();
    };
    window.addEventListener('unhandledrejection', rejectionHandle);
    return () => {
      window.removeEventListener('unhandledrejection', rejectionHandle);
    };
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Main />} errorElement={<RouteError />}>
          <Route path="/" element={<WorkbenchWrapperPage />}>
            <Route index element={<ProtectedComponent component={<HomeScreen />} />} />

            <Route path="ai" element={<ProtectedComponent component={<AiAssistant />} />} />

            <Route path="workbooks" element={<ProtectedComponent component={<HomeScreen />} />} />

            <Route path=":currentFolderId/folder" element={<ProtectedComponent component={<HomeScreen />} />} />

            {_.map(worksheetRoutePaths, (path) => (
              <Route key={path} path={path} element={<ProtectedComponent component={<WorksheetWrapper />} />} />
            ))}

            <Route path="hsa" element={<ProtectedComponent component={<HomeScreenAddOnHost />} />} />

            <Route path="view/:viewId" element={<ProtectedComponent component={<ViewWrapper />} />} />

            <Route path="administration" element={<ProtectedComponent component={<Administration />} />} />

            <Route path="license" element={<ProtectedComponent component={<License />} />} />

            <Route path="logs" element={<ProtectedComponent component={<LogTracker />} />} />

            <Route path=":folderId/project/:projectId" element={<DataLabWrapper />} />
            <Route path="project/:projectId" element={<DataLabWrapper />} />

            <Route
              path="auditTrail"
              element={<ProtectedComponent component={<AuditTrail />} permissions={canReadAuditTrail} />}
            />

            <Route
              path="notifications-management"
              element={
                <React.Suspense>
                  <NotificationsManagement />
                </React.Suspense>
              }
            />

            <Route
              path="notifications-history"
              element={
                <React.Suspense>
                  <NotificationsHistory />
                </React.Suspense>
              }
            />
          </Route>

          <Route path="/headless-capture-standby" element={<>Standing by ...</>} />

          <Route
            path="report-template/:templateId"
            element={<ProtectedComponent component={<ReportTemplateThumbnail />} />}
          />

          <Route path="login" element={<Login />} />

          <Route path="workbook/builder" element={<ProtectedComponent component={<Builder />} />} />

          <Route path="unauthorized" element={<Unauthorized />} />

          <Route path="forbidden" element={<Unauthorized />} />

          <Route
            path="load-error"
            element={
              <LoadError
                header="LOAD_ERROR.SERVER_HEADER"
                message1="LOAD_ERROR.SERVER_MESSAGE1"
                message2="LOAD_ERROR.SERVER_MESSAGE2"
                retryInterval={3000}
              />
            }
          />

          <Route
            path="no-worksheet"
            element={
              <LoadError
                header="LOAD_ERROR.NO_WORKSHEET_HEADER"
                message1="LOAD_ERROR.NO_WORKSHEET_MESSAGE1"
                message2="LOAD_ERROR.RETURN_TO_WORKBENCH"
                retryInterval={0}
              />
            }
          />

          <Route
            path="no-workbook"
            element={
              <LoadError
                header="LOAD_ERROR.NO_WORKBOOK_HEADER"
                message1="LOAD_ERROR.NO_WORKBOOK_MESSAGE1"
                message2="LOAD_ERROR.RETURN_TO_WORKBENCH"
                retryInterval={0}
              />
            }
          />

          <Route path="unsubscribe/:notificationId" element={<Unsubscribe />} />

          <Route
            path="no-websocket"
            element={
              <LoadError
                header="LOAD_ERROR.WEBSOCKET_HEADER"
                message1="LOAD_ERROR.WEBSOCKET_MESSAGE1"
                message2="LOAD_ERROR.WEBSOCKET_MESSAGE2"
                retryInterval={0}
              />
            }
          />
        </Route>

        <Route
          path="/connection-error"
          element={
            <LoadError
              header="LOAD_ERROR.SERVER_HEADER"
              message1="LOAD_ERROR.SERVER_MESSAGE1"
              message2="LOAD_ERROR.SERVER_MESSAGE2"
              retryInterval={3000}
            />
          }
        />
      </>,
    ),
  );
  return (
    <Root>
      <QTip />
      <ToastContainer theme="colored" icon={false} />
      <RouterProvider router={router} />
    </Root>
  );
};
