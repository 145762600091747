import React, { useState } from 'react';
import classNames from 'classnames';
import { useAsyncEffect, useDidUpdate } from 'rooks';
import { Icon } from '@seeqdev/qomponents';
import { UnitOfMeasure } from '@/core/UnitOfMeasure.atom';
import { onEnterKeypress } from '@/core/onEnterKeypress.util';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { Checkbox } from '@/core/Checkbox.atom';
import { isAsset, isDatafile, isDisplay, isTableDefinition, itemIconClass } from '@/utilities/utilities';
import { sqAssetGroupStore, sqTableDefinitionStore } from '@/core/core.stores';
import { onEventExceptDisplayDropdown } from '@/displays/displays.utilities';
import { clearSelection, toggleSelectItem } from '@/search/search.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AnyProperty } from '@/utilities.types';

import { resolveUnitOfMeasure } from '@/utilities/unit.utilities';

export interface SearchResultProps {
  searchResultIcons: (item: AnyProperty) => Promise<React.ReactNode>;
  item: any;
  items?: any[];
  customIconClasses?: string;
  onClickItem: (item: AnyProperty) => void;
  showItemSelection?: boolean;
  isSelectingAsset?: boolean;
  searchTypes?: string[];
  itemSelected?: boolean;
  isSelected?: boolean;
  isAllUnselectable?: boolean;
  highlightItem?: boolean;
  onSelectItem?: (item: any) => void;
  assetGroupEditorVisible?: boolean;
}

export const SearchResult: React.FunctionComponent<SearchResultProps> = ({
  searchResultIcons,
  item,
  items,
  onClickItem,
  customIconClasses,
  onSelectItem,
  isSelectingAsset = false,
  searchTypes,
  itemSelected = false,
  isSelected = false,
  isAllUnselectable = false,
  showItemSelection = false,
  highlightItem = false,
  assetGroupEditorVisible = false,
}) => {
  const itemIconClassNames = `${customIconClasses ? customIconClasses : 'sq-fairly-dark-gray'} pl5 pr10`;
  const arrowIconClassNames = `${customIconClasses ? customIconClasses : 'text-interactive'} mr5`;
  const isEditingAssetGroup = useFluxPath(sqAssetGroupStore, () => sqAssetGroupStore.id === item.id);
  const isEditingTableDefinition = useFluxPath(sqTableDefinitionStore, () => sqTableDefinitionStore.id === item.id);
  const showNavigateCaret = isAsset(item) || isDatafile(item) || item?.hasChildren;
  const resolvedUom = resolveUnitOfMeasure(item);

  const showIcons =
    (!isSelectingAsset && !searchTypes) ||
    (searchTypes && !searchTypes.includes(SeeqNames.Types.Asset) && searchTypes.includes(item.type));

  const [isItemSelected, setSelected] = useState(false);
  const [icons, setIcons] = useState<React.ReactNode>(null);

  useDidUpdate(() => setSelected(itemSelected), [itemSelected]);

  useAsyncEffect(async () => {
    const newIcons = await searchResultIcons(item);
    setIcons(newIcons);
  }, [searchResultIcons, item]);

  const unfocusSearchResult = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    (e.target as HTMLElement).blur();
  };

  const renderCheckbox = () => {
    if (showItemSelection && (!isAllUnselectable || assetGroupEditorVisible)) {
      return (
        <Checkbox
          id={`select_${item.id}`}
          label=""
          classes={classNames({
            invisible:
              isTableDefinition(item) ||
              (!assetGroupEditorVisible && (isAsset(item) || isDisplay(item) || item?.hasChildren)),
          })}
          isChecked={isItemSelected}
          onClick={() => {
            toggleSelectItem(item.id, items);
            setSelected((isSelected) => !isSelected);
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <HoverTooltip text={isEditingAssetGroup ? 'SEARCH_DATA.NO_ACTIONS_WHILE_EDITING' : ''}>
      <div
        data-testid={icons ? 'searchResultWithIcons' : 'searchResult'}
        tabIndex={0}
        onMouseLeave={unfocusSearchResult}
        onClick={() => {
          const isOnlySelectingAssets = searchTypes?.includes(SeeqNames.Types.Asset) && searchTypes.length === 1;
          if (
            (!item.hasChildren && !isOnlySelectingAssets) ||
            (isOnlySelectingAssets && !item.hasChildren && isAsset(item))
          ) {
            onSelectItem?.(item);
          }
        }}
        className={classNames(
          'flexColumnContainer',
          'itemRow',
          'flexNoGrowNoShrink',
          'cursorPointer',
          'pt3',
          'pb6',
          {
            'disabledItemRow text-italic  disabledLook cursorNotAllowed':
              isEditingAssetGroup || isEditingTableDefinition,
          },
          { itemRowSelected: isSelected },
          { highlightFade: highlightItem },
        )}>
        {renderCheckbox()}

        <div
          data-testid="searchResultItem"
          className="flexColumnContainer flexSpaceBetween flexFill mtn3 pt3 mbn6 pb6"
          onKeyUp={onEnterKeypress(() => !isEditingAssetGroup && onClickItem(item))}
          onClick={onEventExceptDisplayDropdown(() => {
            if (!isEditingAssetGroup) {
              onClickItem(item);
              clearSelection();
            }
          })}>
          <div className="flexColumnContainer flexCenter">
            {isSelectingAsset && icons}
            <Icon
              icon={itemIconClass(item)}
              testId="searchItemIcon"
              type="inherit"
              extraClassNames={itemIconClassNames}
              large={true}
            />
          </div>

          <div className="aggressiveWordBreak flexFill">
            <div className="searchResultName">
              <span>{item.name} </span>
              <UnitOfMeasure unitOfMeasure={resolvedUom} />
            </div>
            {item.description && (
              <div className="xsmall pb5" data-testid="searchResultDescription">
                {item.description}
              </div>
            )}
            <div className="xsmall pb2 sq-fairly-dark-gray text-italic">
              {item.assets?.[0]?.formattedName || item?.datasource?.name}
            </div>
          </div>

          <div className="flexRowContainer flexCenter mt2">
            <div data-testid="selectOrNavigate" className="flexColumnContainer flexCenter">
              {showIcons && icons}

              {showNavigateCaret && (
                <Icon icon="fa-chevron-right" type="inherit" extraClassNames={arrowIconClassNames} testId="arrowIcon" />
              )}
            </div>
          </div>
        </div>
      </div>
    </HoverTooltip>
  );
};
