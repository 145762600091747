import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@/main/auth/LoginForm.organism';
import { RegisterForm } from '@/main/auth/RegisterForm.organism';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { fetchAuthenticationProviders } from '@/utilities/authentication.utilities';
import { LoadingBar } from '@/main/LoadingBar.molecule';
import {
  atUserLimit as isAtUserLimit,
  authDefaultProviderId,
  getLoginMessage,
  registrationEnabled as isRegistrationEnabled,
  seeqVersion,
} from '@/services/systemConfiguration.utilities';
import { FakeLink } from '@/core/FakeLink';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { returnToPreviousState } from '@/main/routing.utilities';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import seeqLogoDarkBlueSm from 'resources/img/Seeq_logo_darkBlue_sm.png';
import { DatasourceOutputV1 } from '@/sdk';

export const Login: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [availableDomains, setAvailableDomains] = useState<DatasourceOutputV1[]>([]);
  const [displayLogin, setDisplayLogin] = useState(true);
  const [config, setConfig] = useState<any>({});
  const authenticated = isCsrfSet();

  const { atUserLimit, registrationEnabled } = config;

  const seeqDomain = _.find(availableDomains, ['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]);

  const getDomain = () =>
    _.find(availableDomains, ['datasourceId', getStorageSafely().getItem('sqDomain')]) ||
    _.chain(availableDomains)
      .reject(['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId])
      .sortBy((domain) => {
        const defaultId = authDefaultProviderId();
        if (!defaultId) {
          return 1;
        }
        // Sort the list of domains so that the defaultId is first
        return domain.datasourceId === defaultId ? 0 : 1;
      })
      .first()
      .value() ||
    seeqDomain ||
    ({} as DatasourceOutputV1);

  const [domain, setDomain] = useState<DatasourceOutputV1>(() => getDomain());

  useEffect(() => {
    // check if we're already logged in and if so return to returnTo or workbooks
    if (authenticated) {
      returnToPreviousState();
    }

    document.body.classList.add('color_topic');

    setConfig({
      atUserLimit: isAtUserLimit(),
      registrationEnabled: isRegistrationEnabled(),
      loginMessage: getLoginMessage(),
    });

    // Requests a list of supported authentication providers
    fetchAuthenticationProviders().then(setAvailableDomains);
  }, []);

  useEffect(() => {
    setDomain(getDomain());
  }, [seeqDomain, availableDomains]);

  const isSeeqAuthSelected = domain.datasourceId === SeeqNames.LocalDatasources.Authentication.DatasourceId;

  /**
   * Toggles the display mode between login and registration form.
   */
  const toggleDisplay = () => {
    setDisplayLogin((displayLogin) => !displayLogin);
  };

  const updateDomain = (value: string, property = 'datasourceId') => {
    const newDomain = _.find(availableDomains, [property, value]) || domain;
    setDomain(newDomain);

    return newDomain;
  };

  const renderForms = () => {
    if (_.isEmpty(availableDomains) || _.isEmpty(domain) || _.isEmpty(config)) {
      return (
        <div className="flexRowContainer flexAlignCenter">
          <IconWithSpinner spinning={true} />
        </div>
      );
    }

    return (
      <>
        {displayLogin && <LoginForm domain={domain} availableDomains={availableDomains} updateDomain={updateDomain} />}
        {!displayLogin &&
          (atUserLimit ? (
            <div>{t('LOGIN_PANEL.USER_LIMIT')}</div>
          ) : (
            <RegisterForm
              seeqDomain={seeqDomain}
              domain={domain}
              updateDomain={updateDomain}
              toggleDisplay={toggleDisplay}
            />
          ))}
        {registrationEnabled && displayLogin && isSeeqAuthSelected && (
          <div className="mt25 text-center">
            <span>{t('LOGIN_PANEL.NO_ACCOUNT')}</span>
            &nbsp;
            <FakeLink onClick={toggleDisplay} testId="registerNowLink">
              {t('LOGIN_PANEL.REGISTER_NOW')}
            </FakeLink>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <LoadingBar />
      <div className="loginFooter pl10 pr10 sq-darkish-gray font-size-smaller">
        <small>{seeqVersion()}</small>
      </div>

      <div data-testid="loginPage" className="flexRowContainer fullViewport splashScreenLight loginBackdrop">
        <div className="loginPanel flexRowContainer min-width-400 overlayPanel flexFill flexCenter col-sm-3 offset-sm-2">
          <div className="width-maximum pl20 pr20">
            <div data-testid="logoAndSlogan" className="flexRowContainer flexCenter mb25">
              <img src={seeqLogoDarkBlueSm} className="mb15" />
              <span className="h4 loginSlogan">{t('LOGIN_PANEL.SLOGAN')}</span>

              {!displayLogin && !atUserLimit && (
                <span className="text-color-default">{t('LOGIN_PANEL.REGISTRATION_PROMPT')}</span>
              )}
            </div>

            {renderForms()}
            {config.loginMessage !== '' ? (
              <div className="mt20">
                <ContainerWithHTML id="loginMessage" content={config.loginMessage} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
