import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';

export interface LabelIF extends ValidatingFormComponent<string | React.ReactNode> {
  component: 'LabelFormComponent';
  noMarginBottom?: boolean;
  onIconClick?: () => any;
  valueCustomParams?: Record<string, string | number>;
  onClick?: () => void;
  includeLabelWithHTML?: boolean;
  labelWithHTMLParams?: Record<string, any>;
}

const LabelFormComponentUnwrapped: React.FunctionComponent<LabelIF> = ({
  value,
  tooltip,
  onIconClick,
  onClick,
  testId,
  extraClassNames,
  noMarginBottom,
  tooltipDelay,
  valueCustomParams,
  includeLabelWithHTML = false,
  labelWithHTMLParams,
}) => {
  const { t } = useTranslation();

  let label;
  if (includeLabelWithHTML && _.isString(value)) {
    label = <TranslationWithHTML translationKey={String(value)} translationParams={labelWithHTMLParams} />;
  } else if (_.isString(value)) {
    label = t(value, valueCustomParams);
  } else {
    label = value;
  }

  return (
    <div
      className={classNames('flexColumnContainer', extraClassNames, {
        mb5: !noMarginBottom,
      })}
      onClick={onClick}
      data-testid={testId}>
      {label}
      {tooltip && (
        <Icon
          icon="fa-question-circle"
          large={true}
          tooltip={t(tooltip)}
          tooltipDelay={tooltipDelay}
          extraClassNames="pl5"
          testId={`${testId}_icon`}
          onClick={_.isFunction(onIconClick) ? onIconClick : _.noop}
        />
      )}
    </div>
  );
};

export const LabelFormComponent = React.memo(
  LabelFormComponentUnwrapped,
  (prev, next) =>
    !(prev.value !== next.value || prev.tooltip !== next.tooltip || next.extraClassNames !== prev.extraClassNames),
);
