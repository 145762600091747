import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import _ from 'lodash';
import { attemptDateParse } from '@/datetime/dateTime.utilities';
import { useTranslation } from 'react-i18next';
import { EditableText } from '@/core/EditableText.atom';
import { ControlledTooltip } from '@/core/ControlledTooltip.atom';
import { TimezoneModal } from '@/core/TimezoneModal.molecule';
import { doTrack } from '@/track/track.service';
import { Timezone } from '@/datetime/timezone.service';
import { FakeLink } from '@/core/FakeLink';

interface DateTimeEntryProps {
  testId: string;
  date: moment.Moment;
  otherDate?: moment.Moment;
  updateDate: (date: moment.Moment) => void;
  updateBothDates?: (start: any, end: any, option?: string) => void;
  timezone: Timezone;
  readOnly?: boolean;
  timeZoneReadOnly?: boolean;
  hideTimezone?: boolean;
  dateFormat?: string;
  fieldIdentifier: string;
  trackCategory?: string;
  trackInformation?: string;
  trackAction?: string;
  extraClassName?: string;
  textExtraClassNames?: string;
  inputExtraClassNames?: string;
}

/**
 * Entry and display of date/times, including time zones
 */
export const DateTimeEntry: React.FunctionComponent<DateTimeEntryProps> = (props) => {
  const {
    testId,
    date,
    otherDate,
    updateDate,
    updateBothDates,
    timezone,
    readOnly = false,
    timeZoneReadOnly,
    hideTimezone = false,
    dateFormat,
    fieldIdentifier,
    trackAction,
    trackCategory,
    trackInformation,
    extraClassName = '',
    textExtraClassNames,
    inputExtraClassNames,
  } = props;

  const { t } = useTranslation();

  const [editText, setEditText] = useState('');
  const [tzAbbreviation, setTzAbbreviation] = useState('');
  const [isError, setIsError] = useState(false);
  const [parseError, setParseError] = useState<string | undefined>(undefined);
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const target = useRef(null);

  const updateDisplayValue = useCallback(() => {
    if (date.isValid() && timezone) {
      setEditText(formatDate());
      setTzAbbreviation(formatTimezone());
    }
  }, [date, timezone]);

  useEffect(() => {
    updateDisplayValue();
  }, [timezone, date.valueOf(), updateDisplayValue]);

  const onDateChange = (newDate: string) => {
    if (trackCategory && trackAction) {
      doTrack(trackCategory, trackAction, trackInformation);
    }

    if (newDate.length > 50) {
      showError(`${newDate.substring(0, 40)}...`);
      return;
    }

    const successfulMethod = attemptDateParse({
      date,
      otherDate,
      newDate,
      timezone,
      updateBothDates,
      updateDate,
    });
    successfulMethod ? clearError() : showError(_.trim(newDate));
  };

  const clearError = () => {
    setIsError(false);
    setParseError(undefined);
  };

  const showError = (input: string) => {
    updateDisplayValue();
    setIsError(true);
    setParseError(input);
    setTimeout(clearError, 3000);
  };

  const formatDate = () => date.tz(timezone.name).format(dateFormat ? dateFormat : 'l LT');
  const formatTimezone = () => date.tz(timezone.name).format('z');

  const errorTooltip = (
    <span>
      {t('PARSE_DATE_ERROR')}
      <span className="text-bolder">{parseError}</span>
    </span>
  );

  return (
    <div className={classNames('nowrap dateTimeEntry', extraClassName)}>
      {readOnly && <span className="specDateTime readOnly sq-fairly-dark-gray">{editText}</span>}

      {!readOnly && (
        <>
          {target.current ? (
            <ControlledTooltip
              extraClassNames="errorTooltip"
              target={target.current}
              show={isError}
              id={`error-tooltip-${fieldIdentifier}`}
              formattedText={errorTooltip}
            />
          ) : null}
          <span ref={target}>
            <EditableText
              id={testId}
              testId={testId}
              textClasses={classNames('overflowHidden', 'inlineFlex', textExtraClassNames)}
              inputClasses={classNames('overflowHidden', 'inlineFlex', inputExtraClassNames)}
              value={editText}
              onUpdate={onDateChange}
            />
          </span>
        </>
      )}

      {!hideTimezone && (readOnly || timeZoneReadOnly) && (
        <span className="specTimezone readOnly pl5">{tzAbbreviation}</span>
      )}

      {!hideTimezone && !readOnly && !timeZoneReadOnly && (
        <FakeLink testId="openTimezoneModal" extraClassNames="pl5" onClick={() => setShowTimezoneModal(true)}>
          {tzAbbreviation}
        </FakeLink>
      )}
      {showTimezoneModal && <TimezoneModal close={() => setShowTimezoneModal(false)} userFallback={true} />}
    </div>
  );
};
