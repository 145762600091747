import { PersistenceLevel, Store } from '@/core/flux.service';
import { FixedListSearchV1, PropertyFilterInputV1, PropertyInputV1 } from '@/sdk';
import _ from 'lodash';
import { base64guid } from '@/utilities/utilities';
import { OperatorEnum } from 'sdk/model/PropertyFilterInputV1';

const DEFAULT_FIXED_LIST_SEARCH: FixedListSearchV1 = {
  isInclude: true,
  itemIds: [],
  searchType: '' as any,
};

const DEFAULT_PROPERTY: PropertyFilterInputV1 = {
  operator: '' as any,
  propertyName: '',
  values: [],
};

const DEFAULT_VALUE: PropertyInputV1 = {
  value: '',
};

export type PropertiesList = {
  operator: OperatorEnum;
  propertyName: '';
  values: [];
  searchId: string;
  propertyId: string;
};

export type ValuesList = {
  value: '';
  propertyId: string;
  searchId: string;
};

export const INITIAL_SEARCHES: any[] = [
  {
    ..._.cloneDeep(DEFAULT_FIXED_LIST_SEARCH),
    searchId: base64guid(),
  },
];

export class ItemFinderStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqItemFinderStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      name: '',
      searches: INITIAL_SEARCHES,
      propertiesList: [],
      valuesList: [],
      cronSchedule: [],
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get name(): string {
    return this.state.get('name');
  }

  get searches(): [] {
    return this.state.get('searches');
  }

  get propertiesList(): PropertiesList[] {
    return this.state.get('propertiesList');
  }

  get valuesList(): ValuesList[] {
    return this.state.get('valuesList');
  }

  get cronSchedule(): string[] {
    return this.state.get('cronSchedule');
  }

  protected readonly handlers = {
    RESET_ITEM_FINDER: () => {
      this.initialize();
    },
    SET_EXISTING_ITEM_FINDER: ({
      value,
    }: {
      value: {
        name: string;
        searches: [];
        cronSchedule: string[];
      };
    }) => {
      this.state.set('name', value.name);
      this.state.set('searches', value.searches);
      this.state.set('cronSchedule', value.cronSchedule);
    },
    ITEM_FINDER_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },
    ITEM_FINDER_ADD_SEARCH: () => {
      const searchId = base64guid();
      const newSearch = { ...DEFAULT_FIXED_LIST_SEARCH, searchId };
      this.state.push('searches', newSearch);
    },
    ITEM_FINDER_SET_PROPERTIES_AND_VALUES: ({ searches }: { searches: any }) => {
      const allProperties = searches.flatMap((search: any, index: number) =>
        (search.predicates ?? []).map((property: PropertyFilterInputV1) => ({
          ...property,
          propertyId: base64guid(),
          searchId: index,
        })),
      );
      const allValues = allProperties.flatMap((property: any) =>
        (property.values ?? []).map((value: PropertyInputV1) => ({
          ...value,
          propertyId: property.propertyId,
          searchId: property.searchId,
        })),
      );
      this.state.set('propertiesList', allProperties);
      this.state.set('valuesList', allValues);
    },
    ITEM_FINDER_ADD_PROPERTY: (searchId: string) => {
      const propertyId = base64guid();
      const newProperty = { ...DEFAULT_PROPERTY, searchId, propertyId };
      const newValue = { ...DEFAULT_VALUE, propertyId, searchId };
      this.state.push('propertiesList', newProperty);
      this.state.push('valuesList', newValue);
    },
    ITEM_FINDER_ADD_VALUE: ({ propertyId, searchId }: { propertyId: string; searchId: string }) => {
      const newValue = { ...DEFAULT_VALUE, propertyId, searchId };
      this.state.push('valuesList', newValue);
    },
    ITEM_FINDER_REMOVE_SEARCH: (index: number) => {
      if (index > -1) {
        this.state.splice('searches', [index, 1]);
      }
    },
    ITEM_FINDER_REMOVE_PROPERTY: (propertyIndex: number) => {
      this.state.splice('propertiesList', [propertyIndex, 1]);
    },
    ITEM_FINDER_REMOVE_VALUE: (valueIndex: number) => {
      this.state.splice('valuesList', [valueIndex, 1]);
    },
    ITEM_FINDER_SET_CRON_SCHEDULE: ({ cronSchedule }: { cronSchedule: string[] }) => {
      this.state.set('cronSchedule', cronSchedule);
    },
    ITEM_FINDER_UPDATE_SEARCH: ({
      value,
      index,
    }: {
      value: {
        searchType: string;
        itemIds: [];
        isInclude: boolean;
        swapItemId: any;
        rootAssetId: any;
        ancestorId: any;
        folderId: any;
        properties: [];
        types: [];
        searchId: string;
      };
      index: number;
    }) => {
      this.state.splice('searches', [index, 1, { ...this.state.get('searches', index), ...value }]);
    },
    ITEM_FINDER_UPDATE_PROPERTY: ({
      value,
      index,
    }: {
      value: {
        operator: string;
        propertyName: string;
        values: [];
      };
      index: number;
    }) => {
      this.state.splice('propertiesList', [index, 1, { ...this.state.get('propertiesList', index), ...value }]);
    },
    ITEM_FINDER_UPDATE_VALUE: ({
      value,
      index,
    }: {
      value: {
        value: string;
      };
      index: number;
    }) => {
      this.state.splice('valuesList', [index, 1, { ...this.state.get('valuesList', index), ...value }]);
    },
  };
}
