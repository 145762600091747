import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { SandboxModeBanner } from '@/header/SandboxModeBanner.molecule';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { setShowModal } from '@/reportEditor/pdfExport.actions';
import { canModifyWorkbook as authServiceCanModifyWorkbook } from '@/services/authorization.service';
import { getWorksheetLink } from '@/main/routing.utilities';

interface ViewModeWorksheetActionsIF {
  isViewMode: boolean;
  isEditMode: boolean;
}

export const ViewModeWorksheetActions: React.FunctionComponent<ViewModeWorksheetActionsIF> = (props) => {
  const { isViewMode } = props;

  const { t } = useTranslation();

  const { name, owner, effectivePermissions } = useFlux(sqWorkbookStore);
  const workbook = {
    name,
    owner,
    effectivePermissions,
  };

  const canModifyWorkbook = authServiceCanModifyWorkbook(workbook);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);

  const onClickBadge = () => {
    // We use href to force the page to load. If we don't do this, state from view only mode can leak into edit mode,
    // unintentionally changing a workstep
    window.location.href = getWorksheetLink(
      sqWorkbenchStore.stateParams.workbookId,
      sqWorkbenchStore.stateParams.worksheetId,
    );
  };

  const badgeTooltip = canModifyWorkbook ? 'VIEWING_ONLY.TOOLTIP' : 'VIEWING_ONLY.TOOLTIP_NO_ACCESS';

  return isViewMode ? (
    <div className="cursorPointer flexColumnContainer flexJustifyEnd ml10 flexNoGrowNoShrink flexSelfStretch">
      <div className="viewing-badge width-maximum height-maximum flexColumnContainer">
        <HoverTooltip text={badgeTooltip} placement="bottom">
          <span className="p3">{t('VIEWING_ONLY.HEADER')}</span>
        </HoverTooltip>
      </div>
      <SandboxModeBanner />
      {canModifyWorkbook && (
        <span className="p3">
          <Icon
            onClick={onClickBadge}
            icon="fa-pencil"
            tooltip={t('VIEWING_ONLY.EDIT')}
            tooltipPlacement="bottom"
            large={true}
            type="theme-light"
            testId="viewOnlyEditIcon"
          />
        </span>
      )}
      {isReportBinder && (
        <span className="cursorPointer sq-navbar-default link-no-underline p3">
          <Icon
            extraClassNames="mr7 mt3"
            icon="fc-pdf-export"
            tooltip={t('REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE')}
            tooltipPlacement="bottom"
            type="theme-light"
            large={true}
            onClick={() => setShowModal(true)}
            testId="pdfExportIcon"
          />
        </span>
      )}
    </div>
  ) : null;
};
