// @ts-strict-ignore

import { ItemSelectFormComponent, ItemSelectIF } from '@/formbuilder/ItemSelectFormComponent.atom';
import { ValueWithUnitsFormComponent, ValueWithUnitsIF } from '@/formbuilder/ValueWithUnitsFormComponent';
import { ValueWithSymbolFormComponent, ValueWithSymbolIF } from '@/formbuilder/ValueWithSymbolFormComponent';
import { SearchTitleFormComponent, SearchTitleIF } from '@/formbuilder/SearchTitleFormComponent';
import { LabelFormComponent, LabelIF } from '@/formbuilder/LabelFormComponent.atom';
import { PatternFormComponent, PatternIF } from '@/formbuilder/PatternFormComponent';
import { SelectFormComponent, SelectIF } from '@/formbuilder/SelectFormComponent';
import { RadioButtonGroupFormComponent, RadioButtonsIF } from '@/formbuilder/RadioButtonGroupFormComponent.atom';
import { CheckboxFormComponent, CheckboxIF } from '@/formbuilder/CheckboxFormComponent.atom';
import { IconSelectFormComponent, IconSelectIF } from '@/formbuilder/IconSelectFormComponent.atom';
import { OperatorSelectFormComponent, OperatorSelectIF } from '@/formbuilder/OperatorSelectFormComponent.atom';
import { FormControlFormComponent, FormControlIF } from '@/formbuilder/FormControlFormComponent.atom';
import { FormControlJsonIF, JsonTextAreaFormComponent } from '@/formbuilder/JsonTextAreaFormComponent.atom';
import { ErrorMessageFormComponent, ErrorMessageIF } from '@/formbuilder/ErrorMessageFormComponent.atom';
import { PasswordCheckFormComponent, PasswordCheckIF } from '@/formbuilder/PasswordCheckFormComponent.atom';
import { PasswordGroupFormComponent, PasswordGroupIF } from '@/formbuilder/PasswordGroupFormComponent';
import { SelectIdentityFormComponent, SelectIdentityIF } from '@/formbuilder/SelectIdentityFormComponent.atom';
import { TimeZoneSelectorFormComponent, TimeZoneSelectorIF } from '@/formbuilder/TimeZoneSelectorFormComponent.atom';
import { ClickableLinkFormComponent, ClickableLinkIF } from '@/formbuilder/ClickableLinkFormComponent.atom';
import { ToolStore } from '@/core/AdvancedParameters.atom';
import { CheckboxTableFormComponent, CheckboxTableIF } from '@/formbuilder/CheckboxTableFormComponent.atom';
import { ButtonGroupFormComponent, ButtonGroupIF } from '@/formbuilder/ButtonGroupFormComponent';
import { SelectUnitFormComponent, SelectUnitIF } from '@/formbuilder/SelectUnitFormComponent.atom';
import { FileDropperFormComponent, FileDropperIF } from '@/formbuilder/FileDropperFormComponent.atom';
import {
  CompositesSelectorFormComponent,
  CompositesSelectorIF,
} from '@/formbuilder/CompositeLogicSelectorFormComponent.molecule';
import { MaxCapsuleDurationFormComponent, MaxCapsuleDurationIF } from '@/formbuilder/MaxCapsuleDurationFormComponent';
import { IconFormComponent, IconIF } from '@/formbuilder/IconFormComponent.atom';
import { CapsuleInputFormComponent, CapsuleInputIF } from '@/formbuilder/CapsuleInputFormComponent.molecule';
import { InputTableFormComponent, InputTableIF } from '@/formbuilder/InputTableFormComponent.molecule';
import { StatisticSelectorFormComponent, StatisticSelectorIF } from '@/formbuilder/StatisticSelectorFormComponent.atom';
import { ColorPickerFormComponent, ColorPickerIF } from '@/formbuilder/ColorPickerFormComponent.molecule';
import { PriorityPickerFormComponent, PriorityPickerIF } from '@/formbuilder/PriorityPickerFormComponent.molecule';
import { FormulaVariablesFormComponent, FormulaVariablesIF } from '@/formbuilder/FormulaVariablesFormComponent';
import { FormulaEditorFormComponent, FormulaEditorIF } from '@/formbuilder/FormulaEditorFormComponent';
import { DisplayOnlyFormElementWrapper } from '@/formbuilder/formBuildingBlocks';
import { ImageSelectFormComponent, ImageSelectIF } from '@/formbuilder/ImageSelectFormComponent';
import { CapsuleGroupFormComponent, CapsuleGroupIF } from '@/formbuilder/CapsuleGroupFormComponent';
import { SearchWidgetFormComponent, SearchWidgetIF } from '@/formbuilder/SearchWidgetFormComponent';
import { ScatterPlotRegionFormComponent, ScatterPlotRegionIF } from '@/formbuilder/ScatterPlotRegionFormComponent';
import { DateTimeRangeFormComponent, DateTimeRangeIF } from '@/formbuilder/DateTimeRangeFormComponent';
import { DateTimeEntryFormComponent, DateTimeEntryIF } from '@/formbuilder/DateTimeEntryFormComponent';
import React from 'react';
import { ValueBinsFormComponent, ValueBinsIF } from '@/formbuilder/ValueBinsFormComponent';
import { ButtonWithPopoverFormComponent, ButtonWithPopoverIF } from '@/formbuilder/ButtonWithPopoverFormComponent';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { SelectSearchItemFormComponent, SelectSearchItemIF } from '@/formbuilder/SelectSearchItemFormComponent';
import { SelectFolderFormComponent, SelectFolderProps } from '@/formbuilder/SelectFolderFormComponent';
import { CopyableInputFormComponent, CopyableInputIF } from '@/formbuilder/CopyableInputFormComponent.molecule';
import { ScheduleFormComponent, ScheduleFormComponentProps } from '@/formbuilder/ScheduleFormComponent';

export const FORM_ERROR = 'Error';
export const FORM_WARNING = 'Warning';

export const supportedComponents = {
  CheckboxFormComponent,
  ErrorMessageFormComponent,
  FormControlFormComponent,
  JsonTextAreaFormComponent,
  IconSelectFormComponent,
  ItemSelectFormComponent,
  LabelFormComponent,
  OperatorSelectFormComponent,
  RadioButtonGroupFormComponent,
  SearchTitleFormComponent,
  ValueWithUnitsFormComponent,
  TimeZoneSelectorFormComponent,
  ClickableLinkFormComponent,
  CheckboxTableFormComponent,
  SelectUnitFormComponent,
  FileDropperFormComponent,
  CompositesSelectorFormComponent,
  MaxCapsuleDurationFormComponent,
  IconFormComponent,
  CapsuleInputFormComponent,
  InputTableFormComponent,
  StatisticSelectorFormComponent,
  ColorPickerFormComponent,
  PriorityPickerFormComponent,
  ValueWithSymbolFormComponent,
  PatternFormComponent,
  SelectFormComponent,
  ButtonGroupFormComponent,
  DisplayOnlyFormElementWrapper,
  ImageSelectFormComponent,
  PasswordCheckFormComponent,
  PasswordGroupFormComponent,
  FormulaVariablesFormComponent,
  FormulaEditorFormComponent,
  CapsuleGroupFormComponent,
  SearchWidgetFormComponent,
  SelectIdentityFormComponent,
  ScatterPlotRegionFormComponent,
  DateTimeRangeFormComponent,
  DateTimeEntryFormComponent,
  ValueBinsFormComponent,
  ButtonWithPopoverFormComponent,
  SelectSearchItemFormComponent,
  SelectFolderFormComponent,
  CopyableInputFormComponent,
  ScheduleFormComponent,
};

interface BaseFormElement {
  name: string;
  component: string;
  includeIf?: boolean;
  displayNumber?: boolean;
  // number will be generated by FormBuilder - if you want a number to display set displayNumber to true
  number?: number;
  testId?: string;
  // Setting this to true enables forms to use the form state only. This is for forms that are not fast-followed (like
  // the Edit User Modal)
  skipStore?: boolean;
  extraClassNames?: string;
  // if the field comes with a default value be sure to set this flag to true (once you have the default value) - to
  // ensure that form errors will display properly
  defaultProvided?: boolean;
  fieldNames?: string[];
  customStyles?: any;
  key?: any;
}

export interface ValidatingFormComponent<T> extends BaseFormElement {
  value?: T;
  translationArgument?: Record<string, any>;
  onChange?: (value: NonNullable<T>) => void;
  // return true if invalid, false if valid
  validation?: (value: T) => boolean;
  // true to use default and custom validation, false to only use custom validation
  extendValidation?: boolean;
  // number of milliseconds to wait before showing a tooltip on-hover
  tooltipDelay?: number;
  // text to display on icon tooltip
  tooltip?: string;
  // where to place tooltip
  tooltipPlacement?: TooltipPosition;
  onTooltipClick?: () => void;
  // text to display if validation fails
  customErrorText?: string;
  // associated error params
  customErrorParams?: Record<string, any>;
  label?: string | React.ReactNode;
  required?: boolean;
  wrapperClassNames?: string;
  extraClassNames?: string;
}

export interface FormRow extends BaseFormElement {
  component: 'FormRow';
  components: FormElement[];
}

export interface RemovableFormRow extends BaseFormElement {
  component: 'RemovableFormRow';
  components: FormElement[];
  hideRemoveMessage?: boolean;
  removeAction: () => void;
  wrapperClassNames?: string;
  message: string;
}

export interface FormGroup extends BaseFormElement {
  component: 'FormGroup';
  components: FormElement[];
  showBracket?: boolean;
  showScroll?: boolean;
}

export interface AdvancedFormGroup extends BaseFormElement {
  component: 'AdvancedFormGroup';
  components: FormElement[];
  toolStore: ToolStore;
  toolName: string;
  toolId: string;
  alternateName?: string;
}

export interface RemovableFormGroup extends BaseFormElement {
  component: 'RemovableFormGroup';
  components: FormElement[];
  hideIcon?: boolean;
  iconAction: () => void;
  iconHasMargin?: boolean;
}

/**
 * this wrapper allows adding any child to a form and render it within form groups, rows, or blocks
 * it can only support "display only" children; no validation will be applied.
 * a typical use case for this are tables that only display information, or buttons that require no validation
 */
export interface DisplayOnlyFormElementWrapperIF extends BaseFormElement {
  component: 'DisplayOnlyFormElementWrapper';
  children: React.ReactNode;
}

export type FormElement =
  | FormGroup
  | FormRow
  | AdvancedFormGroup
  | ItemSelectIF
  | ValueWithUnitsIF
  | SearchTitleIF
  | LabelIF
  | IconIF
  | RadioButtonsIF
  | CheckboxIF
  | IconSelectIF
  | CompositesSelectorIF
  | OperatorSelectIF
  | FormControlIF
  | FormControlJsonIF
  | ErrorMessageIF
  | TimeZoneSelectorIF
  | CheckboxTableIF
  | ClickableLinkIF
  | SelectUnitIF
  | FileDropperIF
  | MaxCapsuleDurationIF
  | CapsuleInputIF
  | InputTableIF
  | RemovableFormGroup
  | RemovableFormRow
  | StatisticSelectorIF
  | ColorPickerIF
  | PriorityPickerIF
  | ValueWithSymbolIF
  | PatternIF
  | SelectIF
  | ButtonGroupIF
  | ImageSelectIF
  | PasswordCheckIF
  | FormulaVariablesIF
  | FormulaEditorIF
  | PasswordGroupIF
  | DisplayOnlyFormElementWrapperIF
  | CapsuleGroupIF
  | SearchWidgetIF
  | SelectIdentityIF
  | ScatterPlotRegionIF
  | DateTimeRangeIF
  | DateTimeEntryIF
  | ValueBinsIF
  | ButtonWithPopoverIF
  | SelectSearchItemIF
  | SelectFolderProps
  | CopyableInputIF
  | ScheduleFormComponentProps;
